import React from "react";
import "./styles/main.css";

const Loader = ({ done }) => {
  return (
    <div className="spinner-container d-flex min-h-200px h-100 w-100 justify-content-center align-items-center">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
