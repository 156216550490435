import React, {useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { UsersContext } from "../../context/usersContext";
import { FaHome } from "react-icons/fa";
import { HiSquares2X2 } from "react-icons/hi2";
import { MdHelpCenter } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import { IoLogOut } from "react-icons/io5";
function SideMenu(props) {
  const ctx = useContext(UsersContext);
  const location = useLocation();
  const sidebarRef = useRef(null);

  const openSideMenu = ()=>{
    const sideMenu = document.getElementById("sideMenu");
    const sideMenuContentExpanded = document.getElementById("sideMenuContentExpanded");
    const sideMenuContent = document.getElementById("sideMenuContent");
    const overlay = document.getElementById("sidemenu-overlay");
    if(sideMenu){
      sideMenu.classList.remove("hovlink");
      sideMenu.classList.add("expanded");
    }
    if(sideMenuContentExpanded){
      sideMenuContentExpanded.classList.remove("d-none");
      sideMenuContent.classList.add("d-none");
    }
    if(overlay){
      overlay.classList.remove("d-none")
    }
  }

  const closeSideMenu = ()=>{
    const sideMenu = document.getElementById("sideMenu");
    const sideMenuContentExpanded = document.getElementById("sideMenuContentExpanded");
    const sideMenuContent = document.getElementById("sideMenuContent");
    const overlay = document.getElementById("sidemenu-overlay");

    if(sideMenu){
      sideMenu.classList.remove("expanded");
      sideMenu.classList.add("hovlink");
    }
    if(sideMenuContent){
      sideMenuContent.classList.remove("d-none");
      sideMenuContentExpanded.classList.add("d-none");
    }
    if(overlay){
    overlay.classList.add("d-none")
    }
  }

  const handleMouseEnter = (e) => {
      // Handle mouse enter
      if(navigator.maxTouchPoints<1){

        openSideMenu();
      }
    // setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    closeSideMenu();
    // setIsExpanded(false);
  };

  const togglehamburgerMenu = () => {
    // setIsMenuOpen(!isMenuOpen);
    // setIsExpanded(!isExpanded);
    
    openSideMenu();
  };
  const handleCloseSidebar = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      closeSideMenu();
      const overlay  =document.getElementById("sidemenu-overlay")
      overlay.classList.add("d-none")
    }
  };

  return (
    <div className="sidebarWrapper h-100 d-flex flex-column">
      
        <div className="overlay d-none" id="sidemenu-overlay" onClick={handleCloseSidebar}></div>
      
      <div
        className="hamburgerMenu position-absolute text-light"
        onClick={togglehamburgerMenu}
      >
        <div className="d-flex justify-content-end align-items-start w-100">
          <div className="ms-4">
            <FiMenu size={30} />
          </div>
        </div>
      </div>
      <div
        // ref={sidebarRef}
        // className={`sidebarMenu ${
        //   isExpanded || isMenuOpen ? "expanded" : ""
        // } bg-white p-6  hovlink shadow`}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        id="sideMenu"
        ref={sidebarRef}
        className={`sidebarMenu  flex-column justify-content-between hovlink bg-white p-6   shadow`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={(e)=>{e.stopPropagation();}}
      >
          {/* <div id="sideMenuContentExpandedmini"  className="d-flex flex-column h-100 d-none">
            <Link to="/dashboard" >
              <div className="mb-6 h-50px w-200px  overflow-hidden">
                <img
                  src="/assets/media/logos/collabworxLogo.png"
                  alt="Logo"
                  width="80%"
                  loading="eager"
                />
              </div>
            </Link>
            <div className="fs-3 mt-6">
              <Link to="/dashboard">
                <div

                  className={`
                  ${
                    location.pathname === "/dashboard" ||
                    location.pathname === "/"? "sidebaractive": "hovlink"} p-2 ps-4 cursor-pointer rounded-2  mb-4 overflow-hidden navheight flex-nowrap text-nowrap`}
                >
                  <FaHome className="me-4" />
                  Dashboard
                </div>
              </Link>
              <Link to="/projects">
                <div
                  className={`
                  ${
                    location.pathname === "/projects" ||
                    location.pathname.includes("/project/")
                      ? "sidebaractive"
                      : "hovlink"
                  } p-2 ps-4 cursor-pointer rounded-2  mb-4 overflow-hidden navheight flex-nowrap text-nowrap`}
                >
                  <HiSquares2X2 className="me-4" />
                  Projects
                </div>
              </Link>
              <Link to="/help">
                <div
                  className={`
                  ${
                    location.pathname === "/help" ? "sidebaractive" : "hovlink"
                  } p-2 ps-4 cursor-pointer rounded-2  mb-4 overflow-hidden  flex-nowrap navheight`}
                >
                  <MdHelpCenter className="me-4" />
                  Help
                </div>
              </Link>
            </div>
            <div className="flex-grow-1"></div>
            <div
              className="p-2 ps-4 cursor-pointer rounded-2  mb-4 fs-3 w-100 hovlink overflow-hidden navheight"
              onClick={() => {
                ctx.logout();
              }}
              style={{ marginTop: "auto" }}
            >
              <IoLogOut className="me-4" />
              Logout
            </div>
          </div> */}
          <div id="sideMenuContentExpanded" className="d-flex flex-column h-100 d-none">
            <Link to="/dashboard">
              <div className="mb-6 h-50px w-200px  overflow-hidden">
                <img
                  src="/assets/media/logos/collabworxLogo.png"
                  alt="Logo"
                  width="80%"
                  loading="eager"
                />
              </div>
            </Link>
            <div className="fs-3 mt-6">
              <Link to="/dashboard" >
                <div
                  className={`
                  ${
                    location.pathname === "/dashboard" ||
                    location.pathname === "/"
                      ? "sidebaractive"
                      : "hovlink"
                  } p-2 ps-3 cursor-pointer rounded-2  mb-4 overflow-hidden navheight flex-nowrap text-nowrap`}
                >
                  <FaHome className="me-4" />
                  Dashboard
                </div>
              </Link>
              <Link to="/projects">
                <div
                  className={`
                  ${
                    location.pathname === "/projects" ||
                    location.pathname.includes("/project/")
                      ? "sidebaractive"
                      : "hovlink"
                  } p-2 ps-3 cursor-pointer rounded-2  mb-4 overflow-hidden navheight flex-nowrap text-nowrap`}
                >
                  <HiSquares2X2 className="me-4" />
                  Projects
                </div>
              </Link>
              <Link to="/help">
                <div
                  className={`
                  ${
                    location.pathname === "/help" ? "sidebaractive" : "hovlink"
                  } p-2 ps-3 cursor-pointer rounded-2  mb-4 overflow-hidden  flex-nowrap navheight text-nowrap`}
                >
                  <MdHelpCenter className="me-4" />
                  Help
                </div>
              </Link>
            </div>
            <div className="flex-grow-1"></div>
            <div
              className="p-2 ps-3 cursor-pointer rounded-2  mb-4 fs-3 w-100 hovlink overflow-hidden navheight"
              onClick={() => {
                ctx.socket.close();
                ctx.logout();
              }}
              style={{ marginTop: "auto" }}
            >
              <IoLogOut className="me-4" />
              Logout
            </div>
          </div>
          <div id="sideMenuContent" className="d-flex flex-column h-100">
            <Link to="/dashboard">
              <div className="mb-6 w-50px h-50px overflow-hidden">
                <img
                  src="/assets/media/logos/Collabworx logo 2.png"
                  alt="Logo"
                  width="100%"
                  loading="eager"
                />
              </div>
            </Link>
            <div className="fs-3 mt-6">
              <Link to="/dashboard" className="text-center">
                <div
                  className={`
                  ${
                    location.pathname === "/dashboard" ||
                    location.pathname === "/"
                      ? "sidebaractive"
                      : "hovlink"
                  } p-2 cursor-pointer rounded-2 mb-4 overflow-hidden `}
                >
                  <FaHome />
                </div>
              </Link>
              <Link to="/projects" className="text-center">
                <div
                  className={`
                ${
                  location.pathname === "/projects" ||
                  location.pathname.includes("/project/")
                    ? "sidebaractive"
                    : "hovlink"
                } p-2 cursor-pointer rounded-2  mb-4 overflow-hidden `}
                >
                  <HiSquares2X2 />
                </div>
              </Link>
              <Link to="/help" className="text-center">
                <div
                  className={`
                ${
                  location.pathname === "/help" ? "sidebaractive" : "hovlink"
                } p-2 cursor-pointer rounded-2 mb-4 overflow-hidden `}
                >
                  <MdHelpCenter />
                </div>
              </Link>
            </div>
            <div className="flex-grow-1"></div>
            {/* <div
          className="p-2 cursor-pointer rounded-2 mb-4 fs-1 w-100 hovlink overflow-hidden " */}
            <div
              className="p-2 cursor-pointer rounded-2 text-center mb-4 fs-3 w-100 hovlink overflow-hidden "
              onClick={() => {
                ctx.logout();
              }}
            >
              <IoLogOut />
            </div>
          </div>
      </div>
    </div>
  );
}

export default SideMenu;
