import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useReq from "../../hook/useReq";
import Swal from "sweetalert2";
import ForgotEmailSent from "./ForgotEmailSent";

function ResetPassword(props) {
  const navigate = useNavigate();
  const { error, loading, requestData, response, clear } = useReq();
  const [email, setEmail] = useState("");
  const [progress, setProgress] = useState(false);
  const [authError, setAuthError] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const handleForgotPassword = (e) => {
    e.preventDefault();
    clear();
    setAuthError("");

    if (!email) {
      setAuthError("Email address is required!");
      return;
    }

    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setAuthError("You have entered an invalid email address!");
      return;
    }

    requestData("POST", "/auth/forgot-password", { email });
  };

  useEffect(() => {
    if (response !== null) {
      setEmail("");
      setAuthError("");
      // navigate("/reset-password-email-sent");
      setIsEmailSent(true);
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      const message = typeof error === "string" ? error : error.message;
      setProgress(false);
      Swal.fire({
        title: "Failed!",
        text: `${message}`,
        icon: "error",
        iconColor: "red",
        showConfirmButton: true,
        confirmButtonText: "Try Again",
        confirmButtonColor: "#0a5c7b",
      });
    }
  }, [error]);

  return (
    <div id="kt_body" className="app-blank">
      <div className="d-flex flex-column flex-root vh-100" id="kt_app_root">
        <div
          className="d-flex flex-lg-row-fluid bgi-size-cover flex-1 bgi-position-center order-1 order-lg-2 staticblue"
          style={{
            backgroundImage: "url(assets/media/signin/signin-cover.png)",
          }}
        >
          <div className="d-flex flex-1 justify-content-center align-items-center p-5">
            <div className="d-flex w-75 w-lg-75 p-0 bg-light rounded-3">
              <div className="col-5 d-none d-lg-flex col-md-5 col-lg-7">
                <div className="d-flex justify-content-center align-items-center w-100">
                  <img
                    src="/assets/media/signin/signin-logo.svg"
                    className="px-5 w-100 w-md-75"
                  />
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center p-5 flex-1">
                <div className="d-block text-center d-lg-none mb-5">
                  <img
                    src="/assets/media/signin/collabworx-logo.png"
                    className="px-5 w-100 h-55px"
                  />
                </div>
                {isEmailSent ? (
                  <ForgotEmailSent />
                ) : (
                  <div className="align-items-center d-flex justify-content-center p-md-5 ps-0 ps-lg-0 pt-md-0 pt-lg-5 w-100">
                    <div className="w-100 h-100 p-5 rounded-3 shadow">
                      <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center p-4 bg-light rounded-3">
                        <div className="fs-4 text-center ">
                          <h3>Reset Password</h3>
                        </div>

                        <div className="d-flex justify-content-center align-items-center w-150px h-70px my-6">
                          <img
                            src="/assets/icons/reset-password.png"
                            className="px-5  h-100"
                          />
                        </div>

                        <div className="text-center text-dark">
                          <p className="fw-normal">
                            Please enter the email address that you used to
                            register, and we will send you a link to reset your
                            password via Email.
                          </p>
                        </div>

                        <div className="mt-3 w-100">
                          <form onSubmit={handleForgotPassword}>
                            {authError && (
                              <p style={{ color: "red" }}>{authError}</p>
                            )}
                            {error && (
                              <p style={{ color: "red" }}>{error.message}</p>
                            )}
                            <input
                              placeholder="Email Id"
                              type="email"
                              autoFocus
                              autoComplete="off"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setAuthError("");
                              }}
                              className="bg-collabworx w-100 rounded-1 my-3 px-3 h-35px"
                            />

                            <button
                              type="submit"
                              className="btn collabworx-light my-3 py-2 w-100 text-light"
                            >
                              {loading ? (
                                <div
                                  className="spinner-border"
                                  role="status"
                                ></div>
                              ) : (
                                <span className="indicator-label">
                                  Reset my password
                                </span>
                              )}
                            </button>

                            <div
                              className="d-flex text-dark fs-6 justify-content-center align-items-center mt-4 "
                              style={{ color: "#49798d" }}
                            >
                              <Link to="/signin">
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    color: "#49798d",
                                  }}
                                >
                                  Return to{" "}
                                  <span className="underline">Sign In</span>
                                </p>
                              </Link>
                            </div>
                          </form>
                          {/* <div className="d-flex justify-content-center align-items-center text-dark">
                          <span>
                            <Link
                              to="/termsofuse"
                              target="_blank"
                              className="fs-9 fs-md-8 px-2"
                            >
                              Terms of use
                            </Link>
                          </span>
                          |
                          <span>
                            <Link
                              to="/aboutus"
                              target="_blank"
                              className="fs-9 fs-md-8 px-2"
                            >
                              About us
                            </Link>
                          </span>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
