import React, { useState, useEffect, useContext, useRef } from "react";
import useReq from "../../hook/useReq";
import { UsersContext } from "../../context/usersContext";
function UserProfilePopup(props) {
  const [avatar, setAvatar] = useState();
  const [username, setUsername] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cnfNewPassword, setCnfNewPassword] = useState("");
  const [role, setRole] = useState("");
  const [categoryOfCompany, setCategoryOfCompany] = useState("");
  const { error, requestData, response, clear } = useReq();
  const [authError, setAuthError] = useState("");
  const [progress, setprogress] = useState(false);
  const ctx = useContext(UsersContext);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const handleUpdate = () => {
    setprogress(true);
    if (
      username === "" ||
      mobileNo === "" ||
      currentPassword === "" ||
      cnfNewPassword === "" ||
      newPassword === "" ||
      role === "" ||
      categoryOfCompany === ""
    ) {
      setAuthError("All the Fields Are Mandatory*");
      setprogress(false);
      return;
    }
    if (!/^[a-zA-Z]([a-zA-Z]+){2,}(\s[a-zA-Z]([a-zA-Z]+)*)?$/.test(username)) {
      setAuthError(
        "Only alphabets allowed in name & only first and last name is allowed"
      );
      setprogress(false);
      return;
    }
    if (!/^([+]\d{2})?\d{10}$/.test(mobileNo)) {
      setAuthError("Enter a valid Mobile Number!");
      setprogress(false);
      return;
    }
    if (newPassword) {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          newPassword
        )
      ) {
        setAuthError(
          "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number"
        );
        setprogress(false);
        return;
      }
    }
    if (newPassword !== cnfNewPassword) {
      setAuthError("New Password Does Not Match with Confirm Password*");
      setprogress(false);
      return;
    }

    const formData = new FormData();
    formData.append("username", username);
    formData.append("mobileNo", mobileNo);
    formData.append("currentPassword", currentPassword);
    formData.append("newPassword", newPassword);
    formData.append("role", role);
    formData.append("categoryOfCompany", categoryOfCompany);
    formData.append("image", file);

    requestData("POST", "/user/updateProfile", formData);
  };

  useEffect(() => {
    setUsername((prev) => props.username);
    setMobileNo((prev) => props.mobileNo);
    setAvatar((prev) => props.avatar);
    setRole((prev) => props.role);
    setCategoryOfCompany((prev) => props.categoryOfCompany);
  }, []);

  useEffect(() => {
    if (response && response.success && response.existingUser) {
      props.updateFirstLogin(false);
      ctx.username = response.existingUser.username;
      ctx.isFirstLogin = response.existingUser.isFirstLogin;
      ctx.mobileNo = response.existingUser.mobileNo;
      ctx.role = response.existingUser.role;
      ctx.categoryOfCompany = response.existingUser.categoryOfCompany;
      ctx.avatar = response.existingUser.avatar;

      setprogress(false);
    }
  }, [response, ctx]);

  const handleInputChange = (event) => {
    let pickedFile;

    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        const elements = document.querySelectorAll(".image-input-placeholder");

        // Set the new background image using the data URL
        elements.forEach((element) => {
          element.style.backgroundImage = "url(" + imageDataUrl + ")";
        });
      };

      reader.readAsDataURL(pickedFile);
    }
  };

  useEffect(() => {
    setprogress(false);
  }, [error]);
  const handleDivClick = () => {
    // Trigger the file input programmatically
    fileInputRef.current.click();
  };

  useEffect(() => {
    const img = document.getElementById("inputImage");
    const imageDataUrl =
      process.env.REACT_APP_SOCKETIO + props.avatar.replace(/\\/g, "/");
    img.style.backgroundImage = `url("${imageDataUrl}")`;
  }, [props.avatar]);
  return (
    <div>
      <div
        className={`modal fade show `}
        tabIndex="-1"
        style={{
          display: "block",
          backdropFilter: "brightness(0.7)",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                Update Profile
              </h3>
            </div>
            <div className="modal-body">
              <div className="offcanvas-body">
                <div className="fv-row mb-5 d-flex flex-column justify-content-center align-items-center">
                  <label className="d-block fw-semibold fs-6 mb-5">
                    <span className="required">Update profile picture</span>
                  </label>

                  <div
                    className="image-input image-input-empty image-input-outline image-input-placeholder"
                    data-kt-image-input="true"
                    id="inputImage"
                  >
                    <div
                      className="image-input-wrapper w-125px h-125px"
                      onClick={handleDivClick}
                      style={{ cursor: "pointer" }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleInputChange}
                      ref={fileInputRef}
                      style={{ display: "none" }} // hide the input field
                    />

                    <span
                      className="btn btn-icon btn-circle  w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="cancel"
                      data-bs-toggle="tooltip"
                      title="Cancel Project Logo"
                    >
                      <i className="bi bi-x fs-2" />
                    </span>
                    <span
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      title="Remove Project Logo"
                    >
                      <i className="bi bi-x fs-2" />
                    </span>
                  </div>
                  <div className="form-text">
                    Click on profile picture to change
                  </div>
                </div>
                <br />
                {error && <p style={{ color: "red" }}>{error.message}</p>}
                {authError && <p style={{ color: "red" }}>{authError}</p>}

                <div className="mb-3">
                  <label htmlFor="username" className="form-label ">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="mobileNo" className="form-label ">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mobileNo"
                    value={mobileNo}
                    minLength={10}
                    maxLength={10}
                    onChange={(e) => {
                      setMobileNo(e.target.value);
                    }}
                  />
                </div>
                <div className="fv-row mb-8">
                  <select
                    className="form-select bg-transparent"
                    aria-label="Default select example"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="" defaultValue>
                      Select a Role*
                    </option>
                    <option value="Project Manager">Project Manager</option>
                    <option value="Site Engineer">Site Engineer</option>
                    <option value="Construction Manager">
                      Construction Manager
                    </option>
                    <option value="Architect">Architect</option>
                    <option value="Quantity Surveyor">Quantity Surveyor</option>
                    <option value="Site Supervisor">Site Supervisor</option>
                    <option value="Health and Safety Officer">
                      Health and Safety Officer
                    </option>
                    <option value="Civil Engineer">Civil Engineer</option>
                    <option value="Electrician">Electrician</option>
                    <option value="Plumber">Plumber</option>
                    <option value="Heavy Equipment Operator">
                      Heavy Equipment Operator
                    </option>
                    <option value="Carpenter">Carpenter</option>
                    <option value="Surveyor">Surveyor</option>
                    <option value="Laborers">Laborers</option>
                    <option value="Mechanical Engineer">
                      Mechanical Engineer
                    </option>
                  </select>
                </div>
                <div className="fv-row mb-8">
                  <select
                    className="form-select bg-transparent"
                    aria-label="Default select example"
                    value={categoryOfCompany}
                    onChange={(e) => setCategoryOfCompany(e.target.value)}
                  >
                    <option value="" defaultValue>
                      Category Of Company*
                    </option>
                    <option value="Individual">Individual</option>
                    <option value="Contractor">Contractor</option>
                    <option value="Sub-Contractor">Sub-Contractor</option>
                    <option value="Vendor">Vendor</option>
                    <option value="Sub-Vendor">Sub-Vendor</option>
                    <option value="Employee">Employee</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="mobileNo" className="form-label ">
                    Current password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="current_Password"
                    value={currentPassword}
                    onChange={(e) => {
                      setCurrentPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="mobileNo" className="form-label ">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="new_Password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="mobileNo" className="form-label ">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="cnfNewPassword"
                    value={cnfNewPassword}
                    onChange={(e) => {
                      setCnfNewPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn staticgreen"
                onClick={() => {
                  handleUpdate();
                }}
              >
                {progress ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <span className="indicator-label">Update</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfilePopup;
