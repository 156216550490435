import React from "react";
import Footer from "../Home/Footer";

function AboutUs(props) {
  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex flex-column justify-content-between"
    >
      <div class="container d-flex flex-1 py-20 px-10 px-lg-20">
        <div class="card">
          <div class="terms-heading text-center ">
            <h1 className="text-light">About Us</h1>
          </div>
          <div class="card-body">
            <h2 class="text-center terms-sub-heading">
              Introducing the Collabworx: Your Ultimate Tool for Communication
              and Sharing
            </h2>
            <p class="text-center my-6 fs-2">
              Welcome to the Collabworx, brought to you by AHOU Enterprises Pvt Ltd
            </p>
            <p className="fs-4">
              In today's rapidly evolving construction scenario, sharing and
              communication isn't just an option – it's a need and a necessity.
              That's why we've developed the Collabworx, a revolutionary
              platform designed to empower developers, vendors, and construction
              teams to monitor, evaluate, and enhance the performance of their
              projects like never before.
            </p>
            <p className="fs-4">
              With our app, developers gain unparalleled visibility into the
              implementation of critical site measures. Our app facilitates
              real-time discussions and sharing of documents regarding any
              project in run-time by tracking the construction; providing
              developers with the insights they need to ensure ongoing
              compliance and identify areas for improvement.
            </p>
            <p class="text-center font-weight-bold fs-3">
              Join us in revolutionizing project-wise planning, discussion, and
              sharing with the Collabworx Platform.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
