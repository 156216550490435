import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import Loader from './Loader';
const PrivateRoutes = () => {
  let auth = localStorage.getItem("token")
return (
    auth ?<Suspense fallback={<Loader/>}>
    <Outlet/>
  </Suspense>  : <Navigate to='/signin'/>
  )


  
}

export default PrivateRoutes;
