import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useReq from "../../../hook/useReq";

const TransactionStatus = () => {
  const { error, loading, requestData, response, clear } = useReq();

  const location = useLocation();
  const [paymentDetails, setPaymentDetails] = useState(null);

  const orderId = new URLSearchParams(location.search).get("orderId");

  useEffect(() => {
    if (orderId) {
      requestData("POST", "/user/handleJuspayResponse", { orderId });
    }
  }, [orderId]);

  useEffect(() => {
    if (response) {
      setPaymentDetails(response);
    }
  }, [response]);

  console.log("paymentDetails..........", paymentDetails);

  useEffect(() => {
    if(error) {
        console.log("Fetching error", error);
    }
  }, [error]);

  if (error) {
    const getErrorMessage = (error) => {
      if (typeof error === "object" && error !== null && "message" in error) {
        return error.message;
      }
      return error;
    };


    return (
      <div className="container">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="col-md-7 card p-10">
            <div className="error-container d-flex justify-content-center align-items-center row">
              <div className="w-100px mb-10">
                <img
                  src="/assets/icons/wrong.png"
                  alt="Error Image"
                  className="w-100"
                />
              </div>
              <div className="text-center mb-10">
                <h1 className="fs-1 mb-10">{getErrorMessage(error)}</h1>
               
              </div>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <p>
      {paymentDetails ? (
        <pre>{JSON.stringify(paymentDetails, null, 2)}</pre>
      ) : (
        <p>Loading...</p>
      )}
    </p>
  );
};

export default TransactionStatus;
