import React, { useState, useEffect, useCallback, useMemo } from "react";

const Help = () => {
  const helpQuestions = useMemo(
    () => [
      {
        question: "How do I create an account?",
        answer:
          'To create an account, click on the "Sign Up" button on the homepage. Fill in the required information such as your name, email address, and password. Once completed, click the "Sign Up" button to create your account.',
      },
      {
        question: "How can I change my password?",
        answer:
          'To change your password, go to the "Account Settings" section in your profile. Click on the "Change Password" option and follow the instructions to enter your current password and set a new password.',
      },
      {
        question: "What payment methods are accepted?",
        answer:
          "We accept various payment methods, including credit cards (Visa, Mastercard, American Express), PayPal, and bank transfers. You can choose your preferred payment method during the checkout process.",
      },
      {
        question: "How do I contact customer support?",
        answer:
          'If you need assistance, you can contact our customer support team by clicking on the "Contact Us" link in the navigation menu. Fill out the contact form with your inquiry or reach out to us via the provided phone number or email address.',
      },
      {
        question: "What is your return policy?",
        answer:
          'Our return policy allows you to return items within 30 days of purchase. The items must be unused and in their original packaging. Please review our detailed return policy on the "Returns & Refunds" page for more information.',
      },
      {
        question: "How can I track my order?",
        answer:
          'To track your order, log in to your account and go to the "Order History" section. Find the specific order you want to track and click on the "Track Order" button to view the shipment status and tracking information.',
      },
      {
        question: "Can I cancel my order?",
        answer:
          'Yes, you can cancel your order if it hasn\'t been shipped yet. Go to the "Order History" section in your account, find the order you want to cancel, and click on the "Cancel Order" button. If the order has already been shipped, please contact customer support for further assistance.',
      },
      {
        question: "How do I update my billing address?",
        answer:
          'To update your billing address, go to the "Account Settings" or "Billing Information" section in your profile. Click on the "Edit" or "Update" button next to your billing address and make the necessary changes. Save the changes to update your billing address.',
      },
      {
        question: "What should I do if I forgot my password?",
        answer:
          'If you forgot your password, click on the "Forgot Password" link on the login page. Enter your email address, and we will send you instructions on how to reset your password. Make sure to check your spam or junk folder if you don\'t receive the email.',
      },
      {
        question: "How do I unsubscribe from email notifications?",
        answer:
          'To unsubscribe from email notifications, open one of our emails and scroll to the bottom. Click on the "Unsubscribe" or "Manage Preferences" link, and you will be directed to a page where you can update your email preferences or unsubscribe from specific email lists.',
      },
    ],
    []
  );
  const [randomQuestions, setRandomQuestions] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(-1);

  // Use useCallback to memoize the getRandomQuestions function
  const getRandomQuestions = useCallback(
    (count) => {
      const shuffledQuestions = helpQuestions.sort(() => 0.5 - Math.random());
      return shuffledQuestions.slice(0, count);
    },
    [helpQuestions]
  );

  useEffect(() => {
    const randomQuestions = getRandomQuestions(10);
    setExpandedIndex(-1); // Reset expandedIndex when new random questions are generated
    setRandomQuestions(randomQuestions);
  }, [getRandomQuestions]); // Include getRandomQuestions in the dependency array

  const toggleQuestion = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1); // Collapse the question if it's already expanded
    } else {
      setExpandedIndex(index); // Expand the clicked question
    }
  };

  return (
    <div>
      {/*begin::Navbar*/}
      <div className="card ">
        <div className="card-body pt-9 pb-0">
          {/*begin::Details*/}
          <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
            {/*begin::Image*/}
            {/* <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4">
                              <img className="mw-50px mw-lg-125px" src="assets/media/misc/project-1-logo.png" alt=" " />
                            </div> */}
            {/*end::Image*/}
            {/*begin::Wrapper*/}
            <div className="flex-grow-1">
              {/*begin::Head*/}
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                {/*begin::Details*/}
                <div className="d-flex flex-column">
                  {/*begin::Status*/}
                  <h2 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">
                      Frequently Asked Questions
                    </span>
                  </h2>

                  {/*end::Status*/}
                  {/*begin::Description*/}
                  <span className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">
                    Get answers to frequently asked questions and find
                    assistance for common issues on the Help Page.
                  </span>

                  {/*end::Description*/}
                </div>
                {/*end::Details*/}
                {/* Help page bgin */}

                {/* Help page end */}
                {/*end::Head*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Details*/}
            <div className="separator" />
          </div>
          <div className="d-flex mb-9">
            <div className="text-100 fs-4 w-100">
              {randomQuestions.map((question, index) => (
                <div
                  key={index}
                  // className={cn(index%2==0?"animate-sidebar":"intersectview")}
                >
                  <button
                    className="accordion py-3"
                    onClick={() => toggleQuestion(index)}
                    style={{
                      backgroundColor:
                        expandedIndex === index ? "#264858" : "#49798d",
                      color: "white",
                      cursor: "pointer",
                      padding: "12px",
                      width: "100%",
                      border: "1px solid",
                      borderStyle: "dotted",
                      textAlign: "left",
                      outline: "none",
                      transition: "0.4s",
                      borderRadius: "0",
                      lineHeight: "inherit",
                      overflow: "visible",
                      textTransform: "none",
                      WebkitAppearance: "button",
                      fontWeight: "600",
                      paddingTop: "0.75rem!important",
                      paddingBottom: "0.75rem!important",
                    }}
                  >
                    {question.question}
                    <div
                      id="style-3vYB1"
                      className="style-3vYB1"
                      style={{
                        float: "right",
                      }}
                    >
                      {expandedIndex === index ? "-" : "+"}
                    </div>
                  </button>
                  {expandedIndex === index && <p>{question.answer}</p>}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/*end::Navbar*/}
      </div>
      {/*end::Content*/}
    </div>
  );
};

export default Help;
