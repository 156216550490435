import React from "react";
import { useNavigate } from "react-router-dom";
import "./ResetPassSuccess.css";

function ResetPassSucess(props) {
  const navigate = useNavigate();
  return (
    <div className="align-items-center d-flex justify-content-center p-md-5 ps-0 ps-lg-0 pt-md-0 pt-lg-5 w-100">
      <div className="w-100 h-100 p-5 rounded-3 shadow">
        <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center p-6 bg-light ">
          <div className="fs-4 text-center mb-3">
            <h3>Password Reset Successful!</h3>
          </div>
          <div className="success-animation">
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx={26}
                cy={26}
                r={25}
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>

          <div className="text-center">
            <h4 className="fw-normal">
              Your password has been successfully reset. Please click 'Sign In'
              to go to the login page.
            </h4>
          </div>

          <button
            className="btn collabworx-light my-3 py-2 w-100 text-light mt-10"
            onClick={() => navigate("/signin")}
          >
            <span className="indicator-label">Sign In</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassSucess;
