import { createContext } from "react";

export const initialUserState = {
  token: null,
  name: null,
  email: null,
  username: null,
  about: null,
  avatar: null,
  logout: null,
  socket: null,
  mobileNo: null,
  setAvatar: null,
  projectId: null,
  showAlert: null,
  issuperadmin: null,
  isFirstLogin: null,
  categoryOfCompany: null,
  role: null,
  convId:null,
  isBottomReached:true,
};

export const UsersContext = createContext(initialUserState);
