import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useReq from "../../hook/useReq";
import Modal from "react-modal";
import "./register.css";
import Swal from "sweetalert2";

Modal.setAppElement("#root");

const RegisterNow = ({ update, makeMeFalse }) => {
  const navigate = useNavigate();
  const { error, loading, requestData, response, clear } = useReq();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setName] = useState();
  const [email, setEmail] = useState();
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState();
  const [repPassword, setRepPassword] = useState();
  const [role, setRole] = useState("");
  const [categoryOfCompany, setCategoryOfCompany] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [authError, setAuthError] = useState("");
  const [showModal, setShowModal] = useState(false);

  // const validationSchema = Yup.object().shape({

  //   email: Yup.string().email('Invalid email address').required('Email is required'),
  //   mobile: Yup.string().required('Mobile number is required'),
  //   password: Yup.string()
  //     .required('Password is required')
  //     .min(8, 'Password must be at least 8 characters')
  //     .matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
  //       'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
  //     ),
  //   repeatPassword: Yup.string()
  //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
  //     .required('Repeat Password is required'),
  //   toc: Yup.boolean().oneOf([true], 'You must accept the Terms & Conditions'),
  // });

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   reset,
  // } = useForm({
  //   resolver: yupResolver(validationSchema),
  // });
  // useEffect(() => {
  //   if (response !== null && response.message === "User Created Successfully") {
  //     setShowModal(true);
  //   }
  // }, [response]);

  useEffect(() => {
    if (response !== null && response.status === "success") {
      Swal.fire({
        title: "Success !",
        text: "You have successfully Registered !",
        icon: "success",
        confirmButtonText: "Sign In",
        iconColor: "#0a5c7b",
        confirmButtonColor: "#0a5c7b",
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/signin");
        }
      });
    }
  }, [response, navigate]);

  useEffect(() => {
    if (error) {
      const message = typeof error === "string" ? error : error.message;
      Swal.fire({
        title: "Failed to Register",
        text: `${message}`,
        icon: "error",
        iconColor: "red",
        showConfirmButton: true,
        confirmButtonText: "Try Again",
        confirmButtonColor: "#0a5c7b",
      });
    }
  }, [error]);

  const handleRegistration = (e) => {
    e.preventDefault();
    clear();
    setAuthError("");
    if (!username || username.trim().length < 3) {
      setAuthError("Enter a valid Name");
      return;
    }

    if (!/^[a-zA-Z]([a-zA-Z]+){2,}(\s[a-zA-Z]([a-zA-Z]+)*)?$/.test(username)) {
      setAuthError(
        "Only alphabets allowed in name & only first and last name is allowed"
      );
      return;
    }

    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setAuthError("You have entered an invalid email address!");
      return;
    }

    if (!/^([+]\d{2})?\d{10}$/.test(mobileNo)) {
      setAuthError("Enter a valid Mobile Number!");
      return;
    }
    if (!role) {
      setAuthError("Please Select a Role");
      return;
    }
    if (!categoryOfCompany) {
      setAuthError("Please Select a Category Of Company");
      return;
    }
    if (repPassword !== password) {
      setAuthError("Passwords does not match!!!");
      return;
    }
    if (
      !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        repPassword
      )
    ) {
      setAuthError(
        "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number"
      );
      return;
    }

    if (!isChecked) {
      setAuthError("Please accept terms & Conditions");
      return;
    }

    requestData("POST", "/auth/signup", {
      username,
      email,
      mobileNo,
      password,
      role,
      categoryOfCompany,
      isTermsAndConditionsAccepted: isChecked,
    });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <>
      <div id="kt_body" className="app-blank">
        <div className="d-flex flex-column flex-root vh-100" id="kt_app_root">
          <div
            className="d-flex flex-lg-row-fluid bgi-size-cover flex-1 bgi-position-center order-1 order-lg-2 staticblue"
            style={{
              backgroundImage: "url(assets/media/signin/signin-cover.png)",
            }}
          >
            <div className="d-flex flex-1 justify-content-center align-items-center p-5">
              <div className="d-flex   w-lg-75 p-0 bg-light rounded-3">
                <div className="col-5 d-none d-lg-flex col-md-5 ">
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <img
                      src="/assets/media/signin/signin-logo.svg"
                      className="px-5 w-100 w-md-75"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center p-5 flex-1">
                  <div className="d-block text-center d-lg-none mb-5">
                    <img
                      src="/assets/media/signin/collabworx-logo.png"
                      className="px-5 w-100 h-55px"
                    />
                  </div>
                  <div className="align-items-center d-flex justify-content-center p-md-5 ps-0 ps-lg-0 pt-md-0 pt-lg-5 w-100">
                    <div className="w-100 h-100 p-5 rounded-3 shadow">
                      <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center p-4 bg-light rounded-3 ">
                        <div className="fs-4 text-center mb-3">
                          <h1>Hello!</h1>
                        </div>
                        <div className="fs-5 mb-4">
                          <h3 style={{ color: "#0A5C7B" }}>
                            Sign up to get started
                          </h3>
                        </div>
                        <form
                          className="form w-100"
                          noValidate
                          onSubmit={handleRegistration}
                        >
                          {authError && (
                            <p style={{ color: "red" }}>{authError}</p>
                          )}
                          {error && (
                            <p style={{ color: "red" }}>{error.message}</p>
                          )}
                          <div className="fv-row mb-8">
                            <input
                              type="text"
                              placeholder="First and last name*"
                              // {...register('first name')}
                              className={`form-control bg-transparent `}
                              value={username}
                              onChange={(e) => setName(e.target.value)}
                            />
                            {<div className="invalid-feedback"></div>}
                          </div>
                          <div className="fv-row mb-8">
                            <input
                              type="text"
                              placeholder="Email*"
                              // {...register('email')}
                              className={`form-control bg-transparent `}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {<div className="invalid-feedback"></div>}
                          </div>
                          <div className="fv-row mb-8">
                            <input
                              type="tel"
                              placeholder="Mobile Number*"
                              // {...register('mobile')}
                              className={`form-control bg-transparent `}
                              value={mobileNo}
                              onChange={(e) => {
                                if (
                                  Number(e.target.value) ||
                                  e.target.value === ""
                                ) {
                                  setMobileNo(e.target.value);
                                }
                              }}
                              maxLength={10}
                            />
                            {<div className="invalid-feedback"></div>}
                          </div>

                          <div className="row mb-8">
                            <div className="col-md-6 mb-3 mb-md-0">
                              <select
                                className="form-select bg-transparent"
                                aria-label="Default select example"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                              >
                                <option value="" selected>
                                  Select a Role*
                                </option>
                                <option value="Project Manager">
                                  Project Manager
                                </option>
                                <option value="Site Engineer">
                                  Site Engineer
                                </option>
                                <option value="Construction Manager">
                                  Construction Manager
                                </option>
                                <option value="Architect">Architect</option>
                                <option value="Quantity Surveyor">
                                  Quantity Surveyor
                                </option>
                                <option value="Site Supervisor">
                                  Site Supervisor
                                </option>
                                <option value="Health and Safety Officer">
                                  Health and Safety Officer
                                </option>
                                <option value="Civil Engineer">
                                  Civil Engineer
                                </option>
                                <option value="Electrician">Electrician</option>
                                <option value="Plumber">Plumber</option>
                                <option value="Heavy Equipment Operator">
                                  Heavy Equipment Operator
                                </option>
                                <option value="Carpenter">Carpenter</option>
                                <option value="Surveyor">Surveyor</option>
                                <option value="Laborers">Laborers</option>
                                <option value="Mechanical Engineer">
                                  Mechanical Engineer
                                </option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <select
                                className="form-select bg-transparent"
                                aria-label="Default select example"
                                value={categoryOfCompany}
                                onChange={(e) =>
                                  setCategoryOfCompany(e.target.value)
                                }
                              >
                                <option value="" selected>
                                  Category Of Company*
                                </option>
                                <option value="Individual">Individual</option>
                                <option value="Contractor">Contractor</option>
                                <option value="Sub-Contractor">
                                  Sub-Contractor
                                </option>
                                <option value="Vendor">Vendor</option>
                                <option value="Sub-Vendor">Sub-Vendor</option>
                                <option value="Employee">Employee</option>
                              </select>
                            </div>
                          </div>

                          <div className="row mb-8">
                            <div className="col-md-6 mb-3 mb-md-0">
                              <div className="fv-row position-relative mb-3">
                                <input
                                  className={`form-control bg-transparent`}
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Password*"
                                  name="password"
                                  autoComplete="off"
                                  // {...register('password')}
                                  value={repPassword}
                                  onChange={(e) =>
                                    setRepPassword(e.target.value)
                                  }
                                />
                                <span
                                  className=" position-absolute translate-middle top-50 end-0 me-2 text-dark"
                                  onClick={handleTogglePasswordVisibility}
                                >
                                  {showPassword ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-eye-slash"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                      <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-eye"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg>
                                  )}
                                </span>
                              </div>
                              {<div className="invalid-feedback"></div>}
                            </div>

                            <div className="col-md-6">
                              <div className="fv-row">
                                <input
                                  placeholder="confirm-password *"
                                  name="confirm-password"
                                  // type="password"
                                  type="password"
                                  autoComplete="off"
                                  // {...register('repeatPassword')}
                                  className={`form-control bg-transparent`}
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                {<div className="invalid-feedback"></div>}
                              </div>
                            </div>
                          </div>

                          <div className="fv-row mb-8">
                            <label className="form-check form-check-inline custom-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="toc"
                                value="1"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                              />
                              <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
                                I Accept the
                                <Link
                                  to="/termsofuse"
                                  className="ms-1"
                                  style={{ color: "rgb(10, 92, 123)" }}
                                >
                                  Terms & Conditions
                                </Link>
                              </span>
                            </label>

                            {<div className="invalid-feedback"></div>}
                          </div>
                          <div className="d-grid ">
                            <button
                              type="submit"
                              id="kt_sign_up_submit"
                              className="btn fs-2 text-white"
                              style={{ backgroundColor: "#0A5C7B" }}
                            >
                              {loading ? (
                                <div
                                  className="spinner-border"
                                  role="status"
                                ></div>
                              ) : (
                                <span className="indicator-label">Sign up</span>
                              )}
                            </button>
                          </div>
                        </form>
                        <div className="d-flex justify-content-center align-items-center text-dark mt-6">
                          <span>
                            <a
                              href="/termsofuse"
                              target="_blank"
                              className="fs-9 fs-md-8 px-2"
                            >
                              Terms of use
                            </a>
                          </span>
                          |
                          <span>
                            <a
                              href="/aboutus"
                              target="_blank"
                              className="fs-9 fs-md-8 px-2"
                            >
                              About us
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterNow;
