import React, { useEffect, useState } from "react";
import useReq from "../../../hook/useReq";
import "./SubscriptionPlan.css";
import Footer from "../../Home/Footer";
import Swal from "sweetalert2";

const plans = [
  {
    id: 1,
    name: "Basic",
    orderId: `order_basic_${Date.now()}`,
    amount: 1,
    allowProjects: 2,
    allowUsers: 2,
    allowStorage: "3 GB",
  },
  {
    id: 2,
    name: "Standard",
    orderId: `order_standard_${Date.now()}`,
    amount: 5,
    allowProjects: 10,
    allowUsers: 5,
    allowStorage: "10 GB",
  },
  {
    id: 3,
    name: "Premium",
    orderId: `order_premium_${Date.now()}`,
    amount: 10,
    allowProjects: 40,
    allowUsers: 10,
    allowStorage: "20 GB",
  },
];

const SubscriptionPlan = () => {
  const { error, loading, requestData, response, clear } = useReq();
  const [selectedPlan, setSelectedPlan] = useState(plans[1]);


  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  // initiateJuspayPayment API 
  const handlePayment = (plan) => {
    const { orderId, amount } = plan;
    requestData("POST", "/user/initiateJuspayPayment", { orderId, amount });
    if (error && error.response) {
      console.log(
        `Error: ${error.response.status} Message: ${error.response.data.message}`
      );
    }
  };

  // Loading
  useEffect(() => {
    if(loading) {
      Swal.fire({
        title: "",
        html: `<div className="tick ccc-z-index">
          <div className="tick-mark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="150px"
              height="150px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#49798d"
                stroke-width="6"
                r="32"
                stroke-dasharray="174.35839227423352 60.119464091411174"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="2.0408163265306123s"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                ></animateTransform>
              </circle>
            </svg>
          </div>
          <h2>Initiating Payment</h2>
        </div>`,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
    }
  }, [loading])


  useEffect(() => {
    if (response && response.status) {
      if (response.status === "NEW") {
        const url = response.payment_links.web;
        window.location.href = url;
      } else {
        console.log(`Status: ${response.status} Message: ${response.message}`);
      }
    } else {
      console.log("No response");
    }
  }, [response]);


  useEffect(() => {
    if(error) {

      const getErrorMessage = (error) => {
        if (typeof error === 'object' && error !== null && 'message' in error) {
          return error.message;
        }
        return error;
      };

      Swal.fire({
            title: `${getErrorMessage(error)}`,
            icon: "error",
            iconColor: "red",
            confirmButtonColor: "#0a5c7b",
          });
    }
  }, [error])

  const handlePaymentOption = () => {
    if (selectedPlan) {
      handlePayment(selectedPlan);
    }
  };

  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex flex-column justify-content-between"
    >
      <header className="subscription_header">
        <h1>Our Pricing</h1>
      </header>
      <div className="subscription_cards">
        {plans.map((plan) => (
          <div
            key={plan.orderId}
            className={`subscription_card ${
              selectedPlan && selectedPlan.name === plan.name
                ? "subscription_active"
                : "subscription_shadow"
            }`}
          >
            <ul className="subscription_ul">
              <li className="subscription_pack">{plan.name}</li>
              <li className="subscription_price subscription_bottom-bar fs-1 bolder">
                {plan.amount} Rs
              </li>
              <li className="subscription_bottom-bar">
                {plan.allowProjects} projects Allowed
              </li>
              <li className="subscription_bottom-bar">
                {plan.allowUsers} users Allowed
              </li>
              <li className="subscription_bottom-bar">
                Send up to {plan.allowStorage}
              </li>
              <li className="subscription_cursor-pointer">
                <button
                  className={`subscription_card ${
                    selectedPlan && selectedPlan.name === plan.name
                      ? "subscription_btn subscription_active-btn "
                      : "subscription_btn"
                  }`}
                  onClick={() => handleSelectPlan(plan)}
                >
                  {selectedPlan && selectedPlan.name === plan.name
                    ? "Selected"
                    : "Select"}
                </button>
              </li>
            </ul>
          </div>
        ))}
      </div>
      <div className="subscription_buy-now ">
        <button
          className="subscription_btn subscription_buy-now-btn"
          onClick={handlePaymentOption}
        >
          Buy Now
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default SubscriptionPlan;
