import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UsersContext } from "../../context/usersContext";
import useReq from "../../hook/useReq";
import UserProfilePopup from "./UserProfilePopup";
import Swal from "sweetalert2";
// import UserManagement from "../Auth/UserManagement";
const Dashboard = () => {
  const ctx = useContext(UsersContext);
  const navigate = useNavigate();

  const [isFirstLogin, setIsFirstLogin] = useState();
  const [isProjectCreationStarted, setIsProjectCreationStarted] =
    useState(false);
    const [subscriptionPlans, setSubscriptionPlans] = useState();

  const updateFirstLogin = (value) => {
    setIsFirstLogin(value);
  };

  const { response, error, requestData } = useReq();
  const { response: planResponse, error: planError, requestData: planRequestData } = useReq();
  

  useEffect(() => {
    planRequestData("GET", "/user/subscriptionplans");
  }, [])

  useEffect(() => {
    if(planResponse) {
      setSubscriptionPlans(planResponse.subscriptionPlan);
    }
  }, [planResponse]);

  useEffect(() => {
    if(planError) {
      console.log("planError", planError);
    }
  }, [planError])





  const [projectName, setprojectName] = useState("");
  const [description, setdesc] = useState("");
  const [file, setFile] = useState(null);
  const [projectCreationError, setProjectCreationERROR] = useState("");
  const fileInputRef = useRef(null);

  useEffect(() => {
    localStorage.removeItem("projectid");
  }, []);

  useEffect(() => {
    setIsFirstLogin(ctx.isFirstLogin);
  }, [ctx]);

  const pickedHandler = (event) => {
    let pickedFile;

    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        const elements = document.querySelectorAll(".image-input-placeholder");

        // Set the new background image using the data URL
        elements.forEach((element) => {
          element.style.backgroundImage = "url(" + imageDataUrl + ")";
        });
      };

      reader.readAsDataURL(pickedFile);
    }
  };

  // useEffect(() => {
  //   if (response !== null && response.project) {
  //     setIsProjectCreationStarted(false);
  //     document.getElementById("kt_modal_new_target_cancel").click();
  //     navigate("/projects");
  //     setprojectName();
  //     setdesc();
  //     setFile();
  //     Swal.fire({
  //       title: "Project Created Successfully!",
  //       text: "",
  //       icon: "success",
  //       iconColor: "#0a5c7b",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //   }
  // }, [response]);

  useEffect(() => {
    if (response && response.status) {
      if (response.status === "NEW") {
        const url = response.payment_links.web;
        window.location.href = url;
      } else {
        console.log(`Status: ${response.status} Message: ${response.message}`);
      }
    } else {
      console.log("No response");
    }
  }, [response]);

  const handleprojectcreate = (e) => {
    setIsProjectCreationStarted(true);
    e.preventDefault();
    if (!projectName || projectName.trim().length < 8) {
      setProjectCreationERROR("Project Name should be more than 8 characters ");
      setIsProjectCreationStarted(false);
      return;
    } else if (description.trim().length > 150) {
      setProjectCreationERROR(
        "Project Description must be less than 150 characters "
      );
      setIsProjectCreationStarted(false);
      return;
    } else if (!file) {
      setProjectCreationERROR("Please select an image for Project ");
      setIsProjectCreationStarted(false);
      return;
    } else {
      setProjectCreationERROR("");
    }

    const formData = new FormData();
    formData.append("projectName", projectName.trim());
    formData.append("description", description.trim());
    formData.append("image", file);
    // requestData("POST", "/user/createproject", formData);
    requestData("POST", "/user/initiateJuspayPayment", formData);
  };

  const handleDivClick = () => {
    // Trigger the file input programmatically
    fileInputRef.current.click();
  };

  const handleInputChange = (event) => {
    let pickedFile;

    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        const elements = document.querySelectorAll(".image-input-placeholder");

        // Set the new background image using the data URL
        elements.forEach((element) => {
          element.style.backgroundImage = "url(" + imageDataUrl + ")";
        });
      };

      reader.readAsDataURL(pickedFile);
    }
  };

  useEffect(() => {
    if (isProjectCreationStarted === true) {
      Swal.fire({
        title: "",
        html: `<div className="tick ccc-z-index">
        <div className="tick-mark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150px"
            height="150px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              stroke="#49798d"
              stroke-width="6"
              r="32"
              stroke-dasharray="174.35839227423352 60.119464091411174"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="2.0408163265306123s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        </div>
        <h2>Creating project... Please wait.</h2>
      </div>`,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [isProjectCreationStarted]);

  useEffect(() => {
    if (error) {
      const message = typeof error === "string" ? error : error?.message;
      Swal.fire({
        title: "Failed to create project",
        text: `${message}`,
        icon: "error",
        iconColor: "red",
        showConfirmButton: true,
        confirmButtonText: "Try again",
        confirmButtonColor: "#0a5c7b",
      });
    }
  }, [error]);

  return (
    <>
      <div className="col-xl-12 ps-xl-12 ">
        <div
          className="card bgi-position-y-bottom bgi-position-x-end bgi-no-repeat bgi-size-cover min-h-250px bg-body mb-5 mb-xl-8"
          style={{
            backgroundPosition: "100% 20px",
            backgroundSize: "500px auto",
            backgroundImage: 'url("assets/media/misc/city-cover.png")',
          }}
          dir="ltr"
        >
          <div className="card-body d-flex flex-column justify-content-center ps-lg-12">
            <h3 className="fs-2qx fw-bold mb-7 bluecolor">
              CollabWorx - Curious . Minds . Laboratory
            </h3>
            <h5>
              CollabWorx is a research and prototyping think tank with an
              approach to establish research inspired practice <br /> and
              practice informed by research.{" "}
            </h5>
            <br />
            <div className="m-0 col">
              <div
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_new_target"
                className="btn hover-scale staticgreen py-3 me-5 col-12 col-sm-12 col-md-4 col-lg-3 mw-sm-250px"
              >
                <img
                  src="/assets/icons/create-project.png"
                  className="me-3"
                  height={ctx.issuperadmin ? 30 : 20}
                  alt="view files"
                  width={ctx.issuperadmin ? 30 : 20}
                />
                {ctx.issuperadmin ? <br /> : null}
                <span className="fs-4 fw-semibold">Create Project</span>
              </div>
              {ctx.issuperadmin && (
                <>
                  <Link to="/usermanagement">
                    <div className="btn py-3 my-1 hover-scale me-5 staticgreen col-12 col-sm-12 col-md-4 col-lg-3">
                      <img
                        src="/assets/icons/user-management.png"
                        className="me-3"
                        height={30}
                        alt="view files"
                        width={30}
                      />
                      <br />
                      User Management
                    </div>
                  </Link>
                  <Link to="/projectmanagement">
                    <div className="btn px-6 py-3 my-1 hover-scale staticgreen col-12 col-sm-12 col-md-4 col-lg-3">
                      <img
                        src="/assets/icons/project-management.png"
                        className="me-3"
                        height={30}
                        alt="view files"
                        width={30}
                      />
                      <br />
                      Project Management
                    </div>
                  </Link>

                  {/* <Link to="/groupmanagement">
                                      <div className="btn mx-5 px-6 py-3 my-1 hover-scale staticgreen col-12 col-sm-6 col-md-4 col-lg-3 dashbordbtn">
                                        <img
                                          src="/assets/icons/group-management.png"
                                          className="me-3"
                                          height={30}
                                          alt="view files"
                                          width={30}
                                        />
                                        <br />
                                        Group Management
                                      </div>
                                    </Link> */}
                </>
              )}
              {isFirstLogin && (
                <UserProfilePopup
                  username={ctx.username}
                  mobileNo={ctx.mobileNo}
                  avatar={ctx.avatar}
                  about={ctx.about}
                  role={ctx.role}
                  categoryOfCompany={ctx.categoryOfCompany}
                  updateFirstLogin={updateFirstLogin}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="kt_modal_new_target"
          tabIndex={-1}
          aria-hidden="true"
          style={{ backdropFilter: "brightness(0.7)" }}
        >
          <div className="modal-dialog modal-dialog-centered mw-650px">
            <div className="modal-content rounded">
              <div className="modal-header pb-0 border-0 justify-content-end">
                <div
                  className="btn btn-sm btn-icon "
                  onClick={() => {
                    setProjectCreationERROR((prev) => "");
                    setFile(null);
                    setprojectName("");
                    setdesc("");
                    const elements = document.querySelectorAll(
                      ".image-input-placeholder"
                    );

                    // Set the new background image using the data URL
                    elements.forEach((element) => {
                      element.style.backgroundImage = "";
                    });
                  }}
                  data-bs-dismiss="modal"
                >
                  <img
                    src="/assets/icons/close-blue.png"
                    className="me-2"
                    height={16}
                    alt="view files"
                    width={16}
                  />
                </div>
              </div>
              <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                <form
                  // id="kt_modal_new_target_form"
                  className="form"
                  onSubmit={handleprojectcreate}
                >
                  <div className="mb-13 text-center">
                    <h1 className="mb-3 bluecolor">Create New Project</h1>
                    <div className="text-muted fw-semibold fs-5">
                      If you need more info, please check
                      <a href=" " className="fw-bold greencolor mx-2">
                        Project Guidelines
                      </a>
                      .
                    </div>
                  </div>
                  <div className="d-flex flex-column mb-8 fv-row">
                    <>
                      {projectCreationError && (
                        <p className="mb-3 text-danger fs-5">
                          {projectCreationError} *
                        </p>
                      )}
                    </>
                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                      <span className="required">Project Name</span>
                    </label>
                    <input
                      type="text"
                      value={projectName}
                      onChange={(e) => {
                        setprojectName(e.target.value);
                        setProjectCreationERROR((prev) => "");
                      }}
                      className="form-control form-control-solid"
                      placeholder="Enter Project Name"
                      name="projectname"
                      required
                      autoFocus
                    />
                  </div>
                  <div className="d-flex flex-column mb-8">
                    <label className="fs-6 fw-semibold mb-2">
                      Project Description
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      value={description}
                      onChange={(e) => {
                        setdesc(e.target.value);
                        setProjectCreationERROR((prev) => "");
                      }}
                      rows={3}
                      name="target_details"
                      placeholder="Enter Project Description"
                      required
                    />
                  </div>
                  <div className="d-flex mb-10">
                    <div className="fv-row mb-10">
                      <label className="d-block fw-semibold fs-6 mb-5">
                        <span className="required">Project Logo</span>
                      </label>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            ".image-input-placeholder { background-image: url('assets/media/svg/files/blank-image.svg'); } [data-bs-theme=\"dark\"] .image-input-placeholder { background-image: url('assets/media/svg/files/blank-image-dark.svg'); }",
                        }}
                      />
                      <div
                        className="image-input image-input-empty image-input-outline image-input-placeholder"
                        data-kt-image-input="true"
                      >
                        {/* begin::Preview existing avatar*/}
                        <div
                          className="image-input-wrapper w-125px h-125px"
                          onClick={handleDivClick}
                          style={{ cursor: "pointer" }}
                        />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleInputChange}
                          ref={fileInputRef}
                          style={{ display: "none" }} // hide the input field
                        />

                        <label
                          className="btn btn-icon btn-circle  w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          title="Upload Project Logo"
                        >
                          <input
                            type="file"
                            name="avatar"
                            onChange={(e) => pickedHandler(e)}
                            accept="image/*"
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            className="bi bi-pencil-fill fs-7"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                          </svg>
                          <input type="hidden" name="avatar_remove" />
                        </label>
                        <span
                          className="btn btn-icon btn-circle  w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="cancel"
                          data-bs-toggle="tooltip"
                          title="Cancel Project Logo"
                        >
                          <i className="bi bi-x fs-2" />
                        </span>
                        <span
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="remove"
                          data-bs-toggle="tooltip"
                          title="Remove Project Logo"
                        >
                          <i className="bi bi-x fs-2" />
                        </span>
                      </div>
                      <div className="form-text">
                        Allowed file types: png, jpg, jpeg.
                      </div>
                    </div>
                    <div className="ms-8">
                    <label className="d-block fw-semibold fs-6 mb-5">
                        <span className="">Plan Details</span>
                      </label>
                      {subscriptionPlans && (
                      <div>
                        <label className="form-label fw-bold fs-4 mb-2">{subscriptionPlans[0].name}</label>
                        <ul className="list-group list-group-flush fs-4 mb-2">
                          <li>1) File Management.</li>
                          <li>2) Files Upload and Sharing.</li>
                          <li>3) Communicate with project members.</li>
                        </ul>

                        <p className="fw-bold fs-2 mb-2">Rs {subscriptionPlans[0].amount} <span className="fs-6">(Inclusive of GST)</span></p>
                      </div>
                      )}
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="reset"
                      id="kt_modal_new_target_cancel"
                      className="btn me-5 staticblue hover-scale"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setProjectCreationERROR((prev) => "");
                        setFile(null);
                        setprojectName("");
                        setdesc("");
                        const elements = document.querySelectorAll(
                          ".image-input-placeholder"
                        );
                        elements.forEach((element) => {
                          element.style.backgroundImage = "";
                        });
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn staticgreen hover-scale"
                    >
                      <> Make Payment </>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
